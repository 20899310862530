<template>
  <div class="elevatorQuery">
    <!-- 头部 -->
    <div class="header">
      <!-- <img class="large" src="../../assets/log.png" style="width:140px;height:40px" /> -->
      <div>{{ $t("elevator.elevatorDetails") }} / {{ elevator.no }}</div>
    </div>
    <!-- 电梯名称 -->
    <div class="elevatorName">{{ elevator.name }}</div>
    <!-- 主内容 -->
    <div class="main">
      <el-tabs v-model="activeTab" @tab-click="tabClick">
        <!-- 基本信息 -->
        <el-tab-pane :label="$t('elevator.baseInfo')" name="first">
          <div>
            <el-descriptions :column="3" size="medium">
              <el-descriptions-item :label="$t('elevator.elevatorType')">
                {{ $i18n.isCn ?
                  elevator.elevatorProductTypeName :
                  elevator.elevatorProductTypeNameEn }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.registerCode')">
                {{ elevator.regNo }}
                <div v-if="$i18n.isCn">
                  <router-link :to="'/singleMonitor/' + elevator.id" target="_blank">
                    <span style="color: #409EFF;margin-left: 5px;">
                      {{ $t("workOrder.monitorElevator") }}
                    </span>
                  </router-link>
                </div>
                <div v-else>
                  <router-link :to="'/singleMonitorOld/' + elevator.id" target="_blank">
                    <span style="color: #409EFF;margin-left: 5px;">
                      {{ $t("workOrder.monitorElevator") }}
                    </span>
                  </router-link>
                </div>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.exFactoryNo')">{{ elevator.factoryNo }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.controlCabinetLeaveNo')">
                {{ elevator.controlCabinetLeaveNo
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.useUnit')">{{ elevator.useUnitName }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.manufactured')">
                {{ elevator.createComName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.property')">
                {{ elevator.elevatorPropertyComName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.useStatus')">
                <span v-if="elevator.useState === 1">{{ $t("elevator.useStatus_1") }}</span>
                <span v-else-if="elevator.useState === 2">{{ $t("elevator.useStatus_2") }}</span>
                <span v-else-if="elevator.useState === 3">{{ $t("elevator.useStatus_3") }}</span>
                <span v-else>{{ $t("elevator.useStatus_4") }}</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.whereUsed')">
                {{ $i18n.isCn ? elevator.applyPlaceName :
                  elevator.applyPlaceNameEn }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.serviceDate')">{{ elevator.useDate }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.qualityOrgan')">
                {{ elevator.quantityOrganName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.installationEnterprise')">
                {{ elevator.installComName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.reconstructed')">
                {{ elevator.remakeComName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.ratedSpeedOfElevator')">
                {{ elevator.ratedSpeed
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.installationDate')">
                {{ elevator.installDate
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.exFactoryDate')">
                {{ elevator.factoryDate
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.ratedLoadOfElevator')">
                {{ elevator.ratedLoad
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('company.installPhone')">
                {{ elevator.installComTel
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.elevatorNo')">{{ elevator.ladderNo }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.buildingNo')">{{ elevator.buildingNo }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.maintenanced')">
                {{ elevator.maintComName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.maintWorker')">
                {{ elevator.maintEmpName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.maintWorker') + '2'">
                {{ elevator.maintEmpName1
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.safetyOfficer')">
                {{ elevator.safetyOfficerName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.safetyDirector')">
                {{ elevator.safetyOfficerName
                }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.safetyMainer')">{{ elevator.safetyMainerName
                }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.projectName')">{{ elevator.projectName
                }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.brand')">{{ elevator.brand }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.autoGenerate')">
                {{ elevator.isAutoReport ? $t('common.yes') : $t('common.no') }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.joinStatus')">
                {{ elevator.inNetState ? $t("elevator.joined") : $t("elevator.notJoined") }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.salesman')">
                {{ elevator.salesman }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.salesmanTel')">
                {{ elevator.salesmanTel }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.joinDate')">
                {{ elevator.inNetDate }}</el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.agent')">
                {{ elevator.agentName }}
              </el-descriptions-item>
            </el-descriptions>
            <el-divider content-position="left">{{ $t('elevator.locationInfo') }}</el-divider>
            <el-descriptions :column="3" size="medium">
              <el-descriptions-item :label="$t('elevator.longAntLat')">
                {{ elevator.longitude && elevator.latitude ? (elevator.longitude + ", " + elevator.latitude) :
                  $t('elevator.unknown') }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.address')">
                {{ elevator.address ? elevator.address : $t('elevator.unknown') }}
              </el-descriptions-item>
            </el-descriptions>
            <div id="map" ref="map" style="height: 320px;border-radius: 12px;margin-top: 24px;"></div>
          </div>
        </el-tab-pane>
        <!-- 物联网 -->
        <el-tab-pane :label="$t('elevator.iot')" name="second">
          <div>
            <el-descriptions :column="3" size="medium">
              <el-descriptions-item :label="$t('elevator.monitorType')">
                <span v-if="elevator.monitorType === 1">{{ $t("elevator.monitorType1") }}</span>
                <span v-else-if="elevator.monitorType === 2">{{ $t("elevator.monitorType2") }}</span>
                <span v-else-if="elevator.monitorType === 3">{{ $t("elevator.monitorType3") }}</span>
                <span v-else>{{ $t("elevator.monitorType4") }}</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('tpLink.videoType')">
                <span v-if="elevator.videoType === 1">拾联</span>
                <span v-if="elevator.videoType === 2">TPLink</span>
                <span v-else>{{ $t("elevator.notInstalled") }}</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevatorLcd.elevatorLcd')">
                <span v-if="elevator.mac">{{ elevator.mac }}</span>
                <span v-else>{{ $t("elevator.notInstalled") }}</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.dtuModel')">
                {{ elevator.deviceGatewayVo.deviceGatewayStepVo.deviceModel }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.dtuCode')">
                {{ elevator.dtuCode }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.nodeCode')">
                {{ elevator.nodeCode }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.simCardNo')">
                {{ elevator.deviceGatewayVo.deviceGatewayStepVo.simCode }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('deviceGateway.expirationTime')">
                {{ elevator.deviceGatewayVo.deviceGatewayStepVo.simExpireDate ?
                  elevator.deviceGatewayVo.deviceGatewayStepVo.simExpireDate.substring(0,
                    10) : '' }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('deviceGateway.simICCID')">
                {{ elevator.deviceGatewayVo.deviceGatewayStepVo.simICCID }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.openStatus')">
                <span v-if="elevator.openStatus === 0">{{ $t("elevator.open") }}</span>
                <span v-else-if="elevator.openStatus === 10">{{ $t("elevator.notOpen") }}</span>
                <span v-else>{{ $t("elevator.halfOpen") }}</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.faultTemplate')">
                {{ elevator.faultName }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.accessPlatform')">
                {{ integrationKeyCnList.toString() }}
              </el-descriptions-item>
            </el-descriptions>
            <el-divider content-position="left">{{ $t('elevator.alarmDelayTime') }}</el-divider>
            <el-descriptions :column="3" size="medium">
              <el-descriptions-item :label="$t('elevator.peopleStuckAlarmDelayTime')">
                {{ elevator.tiringTime }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.notAvailableAlarmDelayTime')">
                {{ elevator.unUseTime }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('elevator.finishDelayTime')">
                {{ elevator.finishedTime }}
              </el-descriptions-item>
            </el-descriptions>
            <el-divider content-position="left">{{ $t("elevator.floorComparisonTable") }}</el-divider>
            <el-table :data="elevator.elevatorFloors" max-height="500" border stripe style="width: 100%">
              <el-table-column prop="value" :label="$t('elevator.actualFloor')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.value }}
                </template>
              </el-table-column>
              <el-table-column prop="currentFloorCode" :label="$t('elevator.currentFloorCode')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.currentFloorCode }}
                </template>
              </el-table-column>
              <el-table-column prop="faultFloorCode" :label="$t('elevator.faultFloorCode')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.faultFloorCode }}
                </template>
              </el-table-column>
              <el-table-column prop="displayValue" :label="$t('elevator.displayedFloor')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.displayValue }}
                </template>
              </el-table-column>
              <el-table-column prop="stopStatus" :label="$t('elevator.stopStatus')" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.stopStatus === 0">{{ $t("common.no") }}</span>
                  <span v-if="scope.row.stopStatus === 1">{{ $t("common.yes") }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- 电梯部件 -->
        <el-tab-pane :label="$t('elevator.elevatorParts.elevatorParts')" name="seventh">
          <div style="display: flex;flex-direction: row">
            <div style="width: 230px;">
              <div style="margin: 10px 0;">
                <el-input v-model="filterText" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
              </div>
              <div v-loading="loadingTree" style=" height: calc(100vh - 200px);overflow:auto;">
                <div style="width:100%">
                  <el-tree ref="tree" :data="data" :props="defaultProps" :filter-node-method="filterNode" node-key="id"
                    default-expand-all :expand-on-click-node="false">
                    <span slot-scope="{ node, data }" class="custom-tree-node">
                      <span @click="tree(data)">{{ node.label }}</span>
                    </span>
                  </el-tree>
                </div>
              </div>
            </div>
            <div style="margin-left: 16px; flex: 1;">
              <vm-table ref="elevatorPartsTable" v-loading="loading1" height="33vh" url="elevatorParts"
                :filter.sync="elevatorPartsSearch">
                <el-table-column prop="partsTypeName" :label="$t('elevator.elevatorParts.category')"></el-table-column>
                <el-table-column prop="partsNO" :label="$t('elevator.elevatorParts.number')"></el-table-column>
                <el-table-column prop="partsName" :label="$t('elevator.elevatorParts.name')"></el-table-column>
                <el-table-column prop="partsSn" :label="$t('elevator.elevatorParts.serialNumber')"></el-table-column>
                <el-table-column prop="floor" :label="$t('elevator.elevatorParts.floor')" width="60"
                  align="center"></el-table-column>
                <el-table-column prop="status" :label="$t('elevator.elevatorParts.status')" align="center" width="120">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 20" type="info">
                      {{ $t("elevator.elevatorParts.remove") }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.status === 10" type="success">
                      {{ $t("elevator.elevatorParts.activated") }}
                    </el-tag>
                    <el-tag v-else type="danger">
                      {{ $t("elevator.elevatorParts.notActivated") }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="activateTime" width="160px" align="center"
                  :label="$t('elevator.elevatorParts.activateTime')"></el-table-column>
                <el-table-column prop="activator" :label="$t('elevator.elevatorParts.activator')" width="150"
                  align="center"></el-table-column>
                <el-table-column prop="createTime" :label="$t('elevator.elevatorParts.createTime')" width="160px"
                  align="center"></el-table-column>
              </vm-table>
              <el-divider content-position="left">{{ $t("elevator.elevatorParts.replacementRecord") }}</el-divider>
              <vm-table ref="vmTable2" v-loading="loading2" height="33vh" url="elevatorPartsReplacements"
                :filter.sync="elevatorPartsReplacementsSearch">
                <el-table-column prop="partsTypeName" :label="$t('elevator.elevatorParts.category')"></el-table-column>
                <el-table-column prop="oldPartsName" :label="$t('elevator.elevatorParts.oldParts')"></el-table-column>
                <el-table-column prop="newPartsName" :label="$t('elevator.elevatorParts.newParts')"></el-table-column>
                <el-table-column prop="activator" width="150" align="center"
                  :label="$t('elevator.elevatorParts.activator')"></el-table-column>
                <el-table-column prop="replacementTime" width="160px" align="center"
                  :label="$t('elevator.elevatorParts.replacementTime')"></el-table-column>
              </vm-table>
            </div>
          </div>
        </el-tab-pane>
        <!-- 电梯记录 -->
        <el-tab-pane :label="$t('elevator.record')" name="fifth">
          <el-tabs v-model="innerActiveTab2" class="childTabs" @tab-click="recordTabClick">
            <el-tab-pane v-if="workOrder" :label="$t('elevator.emergencyRecord')" name="a">
              <vm-table ref="vmTableWorkOrder" type="elTabs" url="workOrders/web" :filter.sync="filter_workOrders"
                @clear="clearWorkOrderDateRange">
                <template slot="adSearch">
                  <vm-search :label="$t('common.filter')">
                    <el-input v-model.trim="filter_workOrders.filter" :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search>
                  <vm-search :label="$t('workOrder.alarmType')">
                    <el-select v-model="filter_workOrders.alarmType" :placeholder="$t('common.pleaseSelect')" clearable>
                      <el-option v-for="item in closedTypes" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </vm-search>
                  <vm-search :label="$t('workOrder.alarmTime')">
                    <el-date-picker v-model="workOrderDateRange" :picker-options="pickerOptions"
                      value-format="yyyy-MM-dd" type="daterange" :range-separator="$t('common.to')"
                      :start-placeholder="$t('common.startDate')" :end-placeholder="$t('common.endDate')"
                      @change="handleWorkOrderDateChange">
                    </el-date-picker>
                  </vm-search>
                </template>
                <el-table-column prop="workOrderNo" :label="$t('workOrder.workOrderNo')" width="140px" align="center">
                  <template slot-scope="scope">
                    <router-link :to="'/workOrder/' + scope.row.id" target="_blank">
                      <span style="color: #409EFF">{{ scope.row.workOrderNo }}</span>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')"></el-table-column>
                <el-table-column prop="alarmTime" :label="$t('workOrder.alarmTime')" width="160px"
                  align="center"></el-table-column>
                <el-table-column :label="$t('workOrder.alarmType')" width="160px" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.alarmType === 0">
                      <el-tag type="">{{ $t("workOrder.alarmType_0") }}</el-tag>
                    </div>
                    <div v-if="scope.row.alarmType === 1">
                      <el-tag type="warning">{{ $t("workOrder.alarmType_1") }}</el-tag>
                    </div>
                    <div v-if="scope.row.alarmType === 2">
                      <el-tag type="danger">{{ $t("workOrder.alarmType_2") }}</el-tag>
                    </div>
                    <div v-if="scope.row.alarmType === 3">
                      <el-tag type="danger">{{ $t("workOrder.alarmType_3") }}</el-tag>
                    </div>
                  </template>
                </el-table-column>
              </vm-table>
            </el-tab-pane>
            <el-tab-pane v-if="maintWorkOrder" :label="$t('elevator.maintenanceRecord')" name="b">
              <div v-if="siteName === 'G-Cloud电梯云'">
                <vm-table ref="maintTable" type="elTabs" :filter.sync="maintSearch" url="maintenanceOrder"
                  @clear="clearMaintDateRange">
                  <template slot="adSearch">
                    <vm-search :label="$t('common.filter')">
                      <el-input v-model.trim="maintSearch.filter" :placeholder="$t('common.search')"
                        clearable></el-input>
                    </vm-search>
                    <vm-search :label="$t('maintWorkOrder.maintStatus')">
                      <el-select v-model="maintSearch.maintStatus" style="width:100%"
                        :placeholder="$t('common.pleaseSelect')" clearable>
                        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
                          :value="item.value"></el-option>
                      </el-select>
                    </vm-search>
                    <vm-search :label="$t('maintWorkOrder.completionStatus')">
                      <el-select v-model="maintSearch.completionStatus" style="width:100%"
                        :placeholder="$t('common.pleaseSelect')" clearable>
                        <el-option v-for="item in completionStateOptions" :key="item.value" :label="item.label"
                          :value="item.value"></el-option>
                      </el-select>
                    </vm-search>
                    <vm-search :label="$t('maintWorkOrder.planDate')">
                      <el-date-picker v-model="maintDateRange" value-format="yyyy-MM-dd" type="daterange"
                        :unlink-panels="true" :range-separator="$t('common.to')"
                        :start-placeholder="$t('common.startDate')" :end-placeholder="$t('common.endDate')"
                        @change="handleMaintDateChange">
                      </el-date-picker>
                    </vm-search>
                  </template>
                  <el-table-column prop="orderNum" :label="$t('maintWorkOrder.orderNum')" width="100px" align="center">
                    <template v-slot="scope">
                      <router-link :to="'/newMaintWorkOrder/' + scope.row.id" target="_blank">
                        {{ scope.row.orderNum }}
                      </router-link>
                    </template>
                  </el-table-column>
                  <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="120px"
                    align="center"></el-table-column>
                  <el-table-column prop="elevatorName" :label="$t('maintWorkOrder.elevatorName')"
                    show-overflow-tooltip></el-table-column>
                  <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')"
                    show-overflow-tooltip></el-table-column>
                  <el-table-column prop="maintComName" :label="$t('maintWorkOrder.company')"
                    show-overflow-tooltip></el-table-column>
                  <el-table-column prop="propertyComName" :label="$t('maintWorkOrder.propertyCom')"
                    show-overflow-tooltip></el-table-column>
                  <el-table-column prop="maintDate" :label="$t('maintWorkOrder.maintDate')" width="90px"
                    align="center"></el-table-column>
                  <el-table-column prop="maintTypeName" :label="$t('maintWorkOrder.maintType')" width="90px"
                    align="center"></el-table-column>
                  <el-table-column prop="completionTime" :label="$t('maintWorkOrder.completionTime')" width="160px"
                    align="center"></el-table-column>
                  <el-table-column prop="maintDuration" :label="$t('maintWorkOrder.maintDuration')"
                    width="120px" align="center">
                    <template v-slot="scope">
                      <span v-if="scope.row.maintDuration !== null">
                        {{ scope.row.maintDuration + $t("minutes",) }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="maintainerName1" :label="$t('elevator.maintWorker') + '1'"
                  width="120px" align="center"></el-table-column>
                  <el-table-column prop="maintainerName2" :label="$t('elevator.maintWorker') + '2'"
                  width="120px" align="center"></el-table-column>
                  <el-table-column prop="completionStatus" :label="$t('maintWorkOrder.completionStatus')"
                  width="100px" align="center">
                    <template v-slot="scope">
                      <el-tag v-if="scope.row.completionStatus === 1" type="success">
                        {{ $t("maintWorkOrder.normal") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.completionStatus === 2">
                        {{ $t("maintWorkOrder.advance") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.completionStatus === 3" type="danger">
                        {{ $t("maintWorkOrder.overdue") }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="maintStatus" :label="$t('maintWorkOrder.maintStatus')"
                  width="120px" align="center">
                    <template v-slot="scope">
                      <el-tag v-if="scope.row.maintStatus === 0" type="danger">
                        {{ $t("maintWorkOrder.invalid") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 1" type="warning">
                        {{ $t("maintWorkOrder.inPlan") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 2">
                        {{ $t("maintWorkOrder.notAccepted") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 3">
                        {{ $t("maintWorkOrder.accepted") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 4" type="info">
                        {{ $t("maintWorkOrder.signedIn") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                        {{ $t("maintWorkOrder.completed") }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="creator" :label="$t('maintWorkOrder.creator')" width="95px"
                    align="center"></el-table-column>
                </vm-table>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="yearCheckRecord" :label="$t('elevator.yearCheckRecord')" name="c">
              <vm-table ref="vmTable1" type="elTabs" :filter.sync="filter_yearChecks" url="yearChecks">
                <template slot="adSearch">
                  <vm-search :label="$t('common.filter')">
                    <el-input v-model.trim="filter_yearChecks.filter" :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search>
                </template>
                <el-table-column prop="yearCheckNo" :label="$t('yearCheck.yearCheckNo')" width="180px"
                  align="center"></el-table-column>
                <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')"
                  show-overflow-tooltip></el-table-column>
                <el-table-column prop="quantityOrganName" :label="$t('elevator.qualityOrgan')"
                  show-overflow-tooltip></el-table-column>
                <el-table-column prop="maintenanceComName" :label="$t('elevator.maintenanced')"
                  show-overflow-tooltip></el-table-column>
                <el-table-column prop="checkDate" :label="$t('yearCheck.checkDate')" width="155px"
                  align="center"></el-table-column>
                <el-table-column prop="status" :label="$t('yearCheck.status')" width="120px" align="center">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.status === 0 ? 'info' : 'success'">
                      {{ scope.row.status === 0 ? $t("yearCheck.result.noPass") : $t("yearCheck.result.pass") }}
                    </el-tag>
                  </template>
                </el-table-column>
              </vm-table>
            </el-tab-pane>
            <el-tab-pane v-if="faultRecord" :label="$t('elevator.faultRecord')" name="e">
              <vm-table ref="vmTable" type="elTabs" :filter.sync="filter_faultRecords" url="faultRecords"
                @clear="restTime">
                <template slot="adSearch">
                  <!-- <vm-search v-if="false" :label="$t('elevator.dtuCode')">
                    <el-input v-model.trim="filter_faultRecords.filter" :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search> -->
                  <vm-search :label="$t('workOrder.faultTime')">
                    <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange"
                      :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                      :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                      :clearable="false" @change="handleDateChange">
                    </el-date-picker>
                  </vm-search>
                </template>
                <el-table-column prop="faultCode" :label="$t('workOrder.faultNo')" width="100px"
                  align="center"></el-table-column>
                <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="135px"
                  align="center"></el-table-column>
                <el-table-column prop="elevatorName" :label="$t('elevator.name')"
                  show-overflow-tooltip></el-table-column>
                <el-table-column prop="faultDesc" :label="$t('workOrder.faultType')"
                  show-overflow-tooltip></el-table-column>
                <el-table-column prop="faultTime" :label="$t('workOrder.faultTime')" width="160px"
                  align="center"></el-table-column>
                <el-table-column prop="faultFloorCode" :label="$t('workOrder.faultFloorCode')" width="140px"
                  align="center"></el-table-column>
                <el-table-column prop="displayFaultFloor" :label="$t('workOrder.displayFaultFloor')" width="160px"
                  align="center"></el-table-column>
              </vm-table>
            </el-tab-pane>
            <el-tab-pane v-if="fileRecord" :label="$t('elevator.selfProtectionRecord')" name="f">
              <vm-table ref="fileTable" type="elTabsBtn" :filter.sync="filter_fileRecords" url="files/filePage">
                <template slot="toolbar">
                  <el-upload :action="uploadUrl" :headers="uploadHeaders" :before-upload="beforeUpload"
                    :on-error="handlerError" :on-success="handleSuccess" :data="filter_fileRecords" :multiple="true"
                    :show-file-list="false" accept=".png, .jpeg, .jpg, .doc, .pdf, .docx, .xlsx">
                    <el-button :loading="uploadLoading" icon="el-icon-upload2" size="mini" type="primary"
                      style="background-color: #0747fd;">
                      {{ $t("programCard.upload") }}
                    </el-button>
                  </el-upload>
                </template>
                <template slot="adSearch">
                  <vm-search :label="$t('common.filter')">
                    <el-input v-model.trim="filter_fileRecords.filter" :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search>
                </template>
                <el-table-column prop="name" :label="$t('floorDisplay.name')" show-overflow-tooltip>
                  <template v-if="scope.row.name && scope.row.path" slot-scope="scope">
                    <el-link type="primary" @click="downloadFile(scope.row.path, scope.row.name)">
                      {{ scope.row.name }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="uploadTime" :label="$t('contract.uploadTime')" width="160px"
                  align="center"></el-table-column>
                <el-table-column prop="creator" :label="$t('gatewayVersion.creator')" width="160px"
                  align="center"></el-table-column>
                <el-table-column :label="$t('common.operate')" align="center" width="80px">
                  <template slot-scope="scope">
                    <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">
                      {{ $t("common.delete") }}
                    </el-button>
                  </template>
                </el-table-column>
              </vm-table>
            </el-tab-pane>
            <el-tab-pane v-if="otherRecord" :label="$t('elevator.otherRecord')" name="h">
              <vm-table ref="otherTable" type="elTabs" :filter.sync="filter_otherRecords" url="files/filePage">
                <template slot="toolbar">
                  <el-upload :action="uploadUrl" :headers="uploadHeaders" :before-upload="beforeUpload"
                    :on-error="handlerError" :on-success="handleSuccess" :data="filter_otherRecords" :multiple="true"
                    :show-file-list="false" accept=".png, .jpeg, .jpg, .doc, .pdf, .docx, .xlsx">
                    <el-button :loading="uploadLoading" icon="el-icon-upload2" size="mini" type="primary"
                      style="background-color: #0747fd;">
                      {{ $t("programCard.upload") }}
                    </el-button>
                  </el-upload>
                </template>
                <el-table-column prop="name" :label="$t('floorDisplay.name')" show-overflow-tooltip>
                  <template v-if="scope.row.name && scope.row.path" slot-scope="scope">
                    <el-link type="primary" @click="downloadFile(scope.row.path, scope.row.name)">
                      {{ scope.row.name }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="uploadTime" :label="$t('contract.uploadTime')" width="160px"
                  align="center"></el-table-column>
                <el-table-column prop="creator" :label="$t('gatewayVersion.creator')" width="160px"
                  align="center"></el-table-column>
                <el-table-column :label="$t('common.operate')" align="center" width="80px">
                  <template slot-scope="scope">
                    <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">
                      {{ $t("common.delete") }}
                    </el-button>
                  </template>
                </el-table-column>
              </vm-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { stopStatus } from "../../util/constant";
import auth from "@/util/auth";

const moduleName_productType = "elevatorProductTypes";
const moduleName_potentialFault = "potentialFaults";
const moduleName_maintWorkOrder = "maintWorkOrder";
export default {
  data() {
    return {
      yearCheckRecord: this.$auth(132),
      faultRecord: this.$auth(117),
      workOrder: this.$auth(302),
      maintWorkOrder: this.$auth(312),
      potentialFault: this.$auth(701),
      fileRecord: this.$auth(824),
      otherRecord: this.$auth(825),
      siteName: window.config.siteName,
      maintLoading: false,
      uploadLoading: false,
      maintDateRange: [],
      maintSearch: {
        elevatorId: this.$route.params.elevatorId,
        filter: "",
        completionStatus: "",
        maintStatus: "",
        startTime: "",
        endTime: "",
      },
      stateOptions: [
        { value: "0", label: this.$t("maintWorkOrder.invalid") },
        { value: "1", label: this.$t("maintWorkOrder.inPlan") },
        { value: "2", label: this.$t("maintWorkOrder.notAccepted") },
        { value: "3", label: this.$t("maintWorkOrder.accepted") },
        { value: "4", label: this.$t("maintWorkOrder.signedIn") },
        { value: "5", label: this.$t("common.completed") }],
      completionStateOptions: [
        { value: "1", label: this.$t("maintWorkOrder.normal") },
        { value: "2", label: this.$t("maintWorkOrder.advance") },
        { value: "3", label: this.$t("maintWorkOrder.overdue") }],
      activeTab: "first",
      innerActiveTab1: "one",
      innerActiveTab2: "a",
      InnerShowCondition: "",
      productTypeList: [],
      group: [],
      listData: [],
      map: null,
      closedTypes: [
        {
          value: 0,
          label: this.$t("workOrder.alarmType_0"),
        },
        {
          value: 1,
          label: this.$t("workOrder.alarmType_1"),
        },
        {
          value: 2,
          label: this.$t("workOrder.alarmType_2"),
        },
        {
          value: 3,
          label: this.$t("workOrder.alarmType_3"),
        }],
      dateRange: [],
      filter_faultRecords: {
        filter: "",
        startTime: "",
        endTime: "",
        elevatorId: this.$route.params.elevatorId,
      },
      uploadUrl: window.config.SERVER_URL + "files/upload/elevatorArchives",
      uploadHeaders: { Authorization: auth.getToken() },
      filter_fileRecords: {
        filter: "",
        module: "elevatorArchives",
        relatedId: this.$route.params.elevatorId,
        relatedType: "1",
        fileName: "",
        isImage: false,
      },
      filter_otherRecords: {
        filter: "",
        module: "elevatorArchives",
        relatedId: this.$route.params.elevatorId,
        relatedType: "2",
        fileName: "",
        isImage: false,
      },
      recordTabName: "",
      workOrderDateRange: [],
      filter_workOrders: {
        alarmType: "",
        filter: "",
        startTime: "",
        endTime: "",
        elevatorId: this.$route.params.elevatorId,
      },
      filter_yearChecks: {
        filter: "",
        elevatorId: this.$route.params.elevatorId,
      },
      tableData_faultRecords: [],
      currentPage_maintWorkOrder: 1,
      total_maintWorkOrder: 0,
      tableData_maintWorkOrder: [],
      integrationKeyCnList: [],
      elevator: {
        id: 0,
        no: "",
        mac: "",
        name: "",
        productTypeId: "",
        integrationKeys: [],
        controlCabinetLeaveNo: "",
        elevatorProductTypeName: "",
        elevatorProductTypeNameEn: "",
        brand: "",
        maintType: 0,
        createComId: 0,
        createComName: "",
        model: "",
        address: "",
        regNo: "",
        factoryNo: "",
        factoryDate: "",
        installDate: "",
        applyPlaceName: "",
        useUnitId: 0,
        useUnitName: "",
        maintEmpId: 0,
        maintEmpName: "",
        inNetState: false,
        useState: 1,
        videoState: 0,
        buildingNo: "",
        ladderNo: "",
        inNetDate: "",
        useDate: "",
        quantityOrganId: 0,
        quantityOrganName: "",
        installComId: 0,
        installComName: "",
        remakeComId: "",
        remakeComName: "",
        maintComId: 0,
        maintComName: "",
        projectName: "",
        faultId: 0,
        faultName: "",
        dtuCode: "",
        iccid: "",
        nodeCode: "",
        highestFloor: 0,
        ratedLoad: 0,
        installComTel: "",
        salesman: "",
        salesmanTel: "",
        ratedSpeed: 0,
        rise: 0,
        angle: 0,
        width: 0,
        length: 0,
        voiceType: "",
        warningParamId: 0,
        tiringTime: "",
        unUseTime: "",
        finishedTime: "",
        floorDisplayId: 0,
        floorDisplayName: "",
        elevatorFloors: [],
        elevatorEquip: { voiceType: "", simNum: "", simExpireDate: "" },
        deviceGatewayVo: {
          deviceGatewayStepVo: {
            dtuCode: "",
            deviceModel: "",
            simCode: "",
            simExpireDate: "",
            simICCID: "",
          },
        },
        safetyOfficerName: "",
        safetyDirectorName: "",
        safetyMainerName: "",
        isAutoReport: false,
      },
      integrationList: [],
      searchValue: "",
      filterText: "",
      loadingTree: false,
      data: [
        {
          id: 0,
          name: "全部",
          parentID: 0,
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
      changePart: null,
      partsTypeId: 0,
      loading1: false,
      currentPage1: 1,
      total1: 0,
      tableData1: [],
      loading2: false,
      currentPage2: 1,
      total2: 0,
      tableData2: [],
      elevatorParts: {
        id: 0,
        elevatorID: 0,
        partsID: 0,
        partsName: "",
      },
      elevatorPartsSearch: {
        elevatorId: 0,
        partsTypeId: 0,
      },
      elevatorPartsReplacementsSearch: {
        elevatorId: 0,
        partsTypeId: 0,
      },
      pickerOptions: {
        disabledDate: function (time) {
          return time.getFullYear() < 2022;
        }
      }
    };
  },
  mounted() {
    this.elevator.id = this.$route.params.elevatorId;
    this.getData();
    this.getIntegrationList();
    this.getProductTypeList();
    this.getDate();
    this.getList(1);
  },
  methods: {
    initMap(longitude, latitude) {
      this.map = new window.BMap.Map(this.$refs.map);
      if (longitude && latitude) {
        this.point = new window.BMap.Point(longitude, latitude);
        this.addPoint(longitude, latitude);
        this.map.centerAndZoom(this.point, 16);// 创建中心点以及地图放大级别
      } else {
        this.point = new window.BMap.Point(109.74484, 34.80992);
        this.map.centerAndZoom(this.point, 6);
      }
      this.map.enableScrollWheelZoom(true);// 鼠标缩放
    },
    addPoint(longitude, latitude) {
      const point = new window.BMap.Point(longitude, latitude);
      const marker = new window.BMap.Marker(point); // 创建标注
      marker.customData = { type: "point" };
      this.map.addOverlay(marker);// 将标注添加到地图中
      const opts = {
        width: 250,
        height: 80,
        title: "位置信息",
      };
      const infoWindow = new window.BMap.InfoWindow("电梯名称：" + this.elevator.name + "<br/>" + "详细地址：" + this.elevator.address, opts);
      // 点标记添加点击事件
      marker.addEventListener("click", function () {
        this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
      });
    },
    getList(pageNum) {
      this.loading = true;
      this.$refs.vmTable.getList(pageNum);
      this.loading = false;
    },
    restTime() {
      this.dateRange = [];
      this.filter_faultRecords.startTime = this.aogTime;
      this.filter_faultRecords.endTime = this.nowTime;
      this.dateRange.push(this.aogTime, this.nowTime);
    },
    getDate() {
      let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
      let ago = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 60, 0, 0, 0);
      this.nowTime = this.getTime(now);
      this.aogTime = this.getTime(ago);
      this.dateRange.push(this.aogTime, this.nowTime);
      this.filter_faultRecords.startTime = this.aogTime;
      this.filter_faultRecords.endTime = this.nowTime;
    },
    getTime(now) {
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");

      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      hour = hour.toString().padStart(2, "0");
      minute = minute.toString().padStart(2, "0");
      second = second.toString().padStart(2, "0");

      let yearTime = `${year}-${month}-${date}`;
      let hourTime = `${hour}:${minute}:${second}`;

      return yearTime + " " + hourTime;
    },

    getData() {
      this.$api.getById("elevators", this.elevator.id).then(res => {
        // this.elevator = Object.assign(res.data,this.elevator );
        this.elevator = res.data;
        this.elevator.deviceGatewayVo.deviceGatewayStepVo = this.elevator.deviceGatewayVo.deviceGatewayStepVo ? this.elevator.deviceGatewayVo.deviceGatewayStepVo : "";
        if (this.elevator.integrationKeys) {
          this.elevator.integrationKeys.forEach(item => {
            let list = this.integrationList.filter(integration => integration.integrationKey === item);
            this.integrationKeyCnList.push(list[0].name);
          });
        }
        this.handleProductTypeSelectChange();
        this.initMap(this.elevator.longitude, this.elevator.latitude);
        console.log(this.potentialFault);
        if (this.potentialFault) {
          this.getPtentialFaultData();
        }
        if (this.workOrder) {
          this.getList_workOrder(1);
        }
        if (this.yearCheckRecord) {
          this.getList_yearChecks(1);
        }
        if (this.faultRecord) {
          this.getList_faultRecords(1);
        }
        if (this.fileRecord) {
          this.getList_fileRecords(1);
        }
        if (this.otherRecord) {
          this.getList_otherRecords(1);
        }
        if (this.maintWorkOrder && this.siteName === "G-Cloud电梯云") {
          this.getList_maintWorkOrder(1);
        } else if (this.maintWorkOrder) {
          this.getList_oldMaintWorkOrder(1);
        }
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + error.response.data.message);
        }
      });
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.filter_faultRecords.startTime = dateRange[0];
        this.filter_faultRecords.endTime = dateRange[1];
        if (dateRange.length > 0) {
          let fullTime = [];
          for (let i = 0; i < dateRange.length; i++) {
            let time = dateRange[i].split(" ");

            let fullYear = time[0].split("-");
            let year = fullYear[0];
            let month = fullYear[1];
            let day = fullYear[2];

            // let fullDay = time[1].split(":");
            // let hour = fullDay[0];
            // let minute = fullDay[1];
            // let second = fullDay[2];

            let date = new Date(year, month, day, 0, 0, 0);
            fullTime.push(date.getTime());
          }
          if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) >= 60) {
            this.$message.error(this.$t("common.sevenTime"));
            this.dateRange = [this.aogTime, this.nowTime];
            this.filter_faultRecords.startTime = this.aogTime;
            this.filter_faultRecords.endTime = this.nowTime;
          }
        }
      } else {
        this.filter_faultRecords.startTime = "";
        this.filter_faultRecords.endTime = "";
      }
    },
    handleWorkOrderDateChange(dateRange) {
      if (dateRange != null) {
        this.filter_workOrders.startTime = dateRange[0];
        this.filter_workOrders.endTime = dateRange[1];
      } else {
        this.filter_workOrders.startTime = "";
        this.filter_workOrders.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
    clearWorkOrderDateRange() {
      this.workOrderDateRange = [];
    },
    clearMaintDateRange() {
      this.maintDateRange = [];
    },
    handleMaintDateChange(maintDateRange) {
      if (maintDateRange != null) {
        this.maintSearch.startTime = maintDateRange[0];
        this.maintSearch.endTime = maintDateRange[1];
      } else {
        this.maintSearch.startTime = "";
        this.maintSearch.endTime = "";
      }
    },
    handleProductTypeSelectChange() {
      for (let productTypeItem of this.productTypeList) {
        if (productTypeItem.value === this.elevator.productTypeId) {
          if (productTypeItem.label === "自动扶梯") {
            this.InnerShowCondition = "自动扶梯";
          } else if (productTypeItem.label === "自动人行道") {
            this.InnerShowCondition = "自动人行道";
          } else {
            this.InnerShowCondition = "其他";
          }
        }
      }
    },
    getProductTypeList() {
      this.$api.getList(moduleName_productType).then(response => {
        this.productTypeList = [];
        for (let productType of response.data) {
          let item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName };
          this.productTypeList.push(item);
        }
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    getPtentialFaultData() {
      this.$api.getById(moduleName_potentialFault, this.elevator.id).then(res => {
        this.listData = res.data;
        this.initGroup();
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + error.response.data.message);
        }
      });
    },
    initGroup() {
      for (let dataItem of this.listData) {
        this.setGroup(dataItem.type);
      }
    },
    setGroup(type) {
      let result = false;
      for (let groupItem of this.group) {
        if (type === groupItem) {
          result = true;
        }
      }
      if (!result) {
        this.group.push(type);
      }
    },
    handleClick(data) {
      if (data.value > 0) {
        let elevatorId = this.elevator.id;
        let potentialFaultSettingId = data.potentialFaultSettingId;
        let beginTime = data.beginTime;
        let pro = {
          "elevatorId": elevatorId,
          "potentialFaultSettingId": potentialFaultSettingId,
          "beginTime": beginTime,
        };
        this.$refs.detailsPage.open(pro);
      }
    },
    getList_workOrder(pageIndex) {
      this.$refs.vmTableWorkOrder.getList(pageIndex);
    },
    getList_yearChecks(pageIndex) {
      this.$refs.vmTable1.getList(pageIndex);
    },
    getList_maintWorkOrder(pageIndex) {
      this.maintLoading = true;
      this.$refs.maintTable.getList(pageIndex);
      this.maintLoading = false;
    },
    getList_oldMaintWorkOrder(pageIndex) {
      this.currentPage_maintWorkOrder = pageIndex;
      let params = {
        pageIndex,
        elevatorId: this.elevator.id,
      };
      this.$api.getList(moduleName_maintWorkOrder, params).then(res => {
        let data = res.data;
        this.tableData_maintWorkOrder = data.list;
        this.total_maintWorkOrder = data.total;
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    getList_faultRecords(pageIndex) {
      this.$refs.vmTable.getList(pageIndex);
    },
    getList_otherRecords(pageIndex) {
      this.$refs.otherTable.getList(pageIndex);
    },
    getList_fileRecords(pageIndex) {
      this.$refs.fileTable.getList(pageIndex);
    },
    downloadFile(url, name) {
      fetch(url).then(response => response.blob()).then(result => {
        let objectUrl = window.URL.createObjectURL(result);
        let link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute("download", name);
        link.click();
        link.remove();
      });
    },
    beforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      let size = file.size / 1024 / 1024;
      if (lastName.toLowerCase() === ".png" ||
        lastName.toLowerCase() === ".jpeg" ||
        lastName.toLowerCase() === ".jpg") {
        if (size > 3) {
          this.$message.error(this.$t("programCard.imageFiles"));
          return false; // 返回false中止上传
        } else {
          if (this.recordTabName === "f") {
            this.filter_fileRecords.fileName = fileName;
            this.filter_fileRecords.isImage = true;
          }
          if (this.recordTabName === "h") {
            this.filter_otherRecords.fileName = fileName;
            this.filter_otherRecords.isImage = true;
          }
        }
      }
      if (lastName.toLowerCase() === ".doc" ||
        lastName.toLowerCase() === ".docx" ||
        lastName.toLowerCase() === ".pdf" ||
        lastName.toLowerCase() === ".xlsx") {
        if (size > 5) {
          this.$message.error(this.$t("programCard.videoFiles"));
          return false; // 返回false中止上传
        } else {
          if (this.recordTabName === "f") {
            this.filter_fileRecords.fileName = fileName;
            this.filter_fileRecords.isImage = true;
          }
          if (this.recordTabName === "h") {
            this.filter_otherRecords.fileName = fileName;
            this.filter_otherRecords.isImage = true;
          }
        }
      }
      if (lastName.toLowerCase() !== ".png" &&
        lastName.toLowerCase() !== ".jpeg" &&
        lastName.toLowerCase() !== ".jpg" &&
        lastName.toLowerCase() === ".doc" &&
        lastName.toLowerCase() === ".docx" &&
        lastName.toLowerCase() === ".pdf" &&
        lastName.toLowerCase() === ".xlsx"
      ) {
        this.$message.error(this.$t("programCard.fileType"));
        return false; // 返回false中止上传
      }
    },
    handleSuccess(res) {
      if (this.recordTabName === "f") {
        this.getList_fileRecords(1);
      }
      if (this.recordTabName === "h") {
        this.getList_otherRecords(1);
      }
      this.uploadLoading = false;
      this.$message.success(this.$t("programCard.uploadSuccessful"));
    },
    handlerError(err) {
      this.$message.error(this.$t("contract.uploadFailure"));
      this.uploadLoading = false;
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete"), this.$t("common.tips"), { type: "warning" }).then(() => {
        this.$http.delete("files", row.id).then(() => {
          if (this.recordTabName === "f") {
            this.getList_fileRecords(1);
          }
          if (this.recordTabName === "h") {
            this.getList_otherRecords(1);
          }
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
    getStopStatus(type) {
      return stopStatus[type];
    },
    changePage_maintWorkOrder(pageIndex) {
      this.getList_oldMaintWorkOrder(pageIndex);
    },
    compareTodayDate(aaa) {
      let date = new Date();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      this.today = date.getFullYear() + "-" + month + "-" + strDate;
      return new Date(aaa) < new Date(this.today);
    },
    filterNode(value, data, node) {
      if (!value) {
        return true;
      }
      if (node.parent.parent !== null && node.parent.visible) {
        return true;
      }
      return node.label.indexOf(value) !== -1;
    },
    getTreeData() {
      this.loadingTree = true;
      this.$axios.get("partsTypes/tree").then((res) => {
        this.data[0].children = res.data;
        this.loadingTree = false;
      });
    },
    getList1(pageIndex) {
      this.elevatorPartsSearch.elevatorId = this.elevator.id;
      this.elevatorPartsSearch.partsTypeId = this.partsTypeId;
      this.$refs.elevatorPartsTable.getList(pageIndex);
    },
    getList2(pageIndex) {
      this.elevatorPartsReplacementsSearch.elevatorId = this.elevator.id;
      this.elevatorPartsReplacementsSearch.partsTypeId = this.partsTypeId;
      this.$refs.vmTable2.getList(pageIndex);
    },
    tree(data) {
      this.partsTypeId = data.id;
      this.refreshList(1);
    },
    refreshList(pageIndex) {
      // 两个请求现在都执行完成
      this.$axios.all([this.getList1(pageIndex), this.getList2(1)]).then(this.$axios.spread(function (acct, perms) {
      }));
    },
    tabClick(tab, event) {
      if (tab.name === "seventh") {
        this.partsTypeId = 0;
        this.defaultProps = "";
        this.getList1(1);
        this.getList2(1);
        this.getTreeData();
      }
    },
    recordTabClick(tab, event) {
      this.recordTabName = tab.name;
    },
  },
};
</script>

<style lang="scss">
.elevatorQuery {
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  padding: 5px 32px;
  box-sizing: border-box;

  /* 头部 */
  .header {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #4d4d4d;
  }

  /* 电梯名称 */
  .elevatorName {
    font-size: 20px;
    color: #0747FD;
    line-height: 40px;
  }

  /* 主内容 */
  .main {
    flex: 1;

    .el-descriptions {
      border-radius: 12px;
      overflow: hidden;

      .el-descriptions__body {
        padding: 20px 0 10px 32px;
      }
    }

    .el-divider--horizontal {
      margin: 20px 0;
    }

    /* 电梯记录 */
    .childTabs {
      .el-tabs__header {
        background: #EBEBEB;
        border-radius: 12px;
        border: 1px solid #ccc;
        padding-left: 12px;

        .el-tabs__nav-wrap {
          height: 48px;
          line-height: 48px;
        }

        .el-tabs__nav-wrap::after {
          height: 0;
        }

        .el-tabs__item {
          font-size: 14px;
          padding: 0 20px;
          border-radius: 12px;

          &.is-active {
            color: #2E64FD;
            background-color: #fff;
            height: 32px;
            line-height: 32px;
          }
        }
      }

      .el-tabs__active-bar {
        height: 0;
      }
    }
  }

  /* 覆盖element样式 */
  .el-tabs__item {
    font-size: 16px;
    color: #4d4d4d;
  }

  .el-tabs__header {
    padding-left: 32px;
  }
}
</style>
